var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.onClose,
          expression: "onClose"
        }
      ],
      staticClass: "labels-container"
    },
    [
      _c("div", { staticClass: "triangle" }, [
        _c(
          "svg",
          { attrs: { height: "12", viewBox: "0 0 24 12", width: "24" } },
          [
            _c("path", {
              attrs: {
                d: "M20 12l-8-8-12 12",
                fill: "var(--white)",
                "fill-rule": "evenodd",
                stroke: "var(--s-50)",
                "stroke-width": "1px"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header flex-between" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("BULK_ACTION.LABELS.ASSIGN_LABELS")))
          ]),
          _vm._v(" "),
          _c("woot-button", {
            attrs: {
              size: "tiny",
              variant: "clear",
              "color-scheme": "secondary",
              icon: "dismiss"
            },
            on: { click: _vm.onClose }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "labels-list" }, [
        _c("header", { staticClass: "labels-list__header" }, [
          _c(
            "div",
            { staticClass: "label-list-search flex-between" },
            [
              _c("fluent-icon", {
                staticClass: "search-icon",
                attrs: { icon: "search", size: "16" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.query,
                    expression: "query"
                  }
                ],
                ref: "search",
                staticClass: "label--search_input",
                attrs: { type: "search", placeholder: "Search" },
                domProps: { value: _vm.query },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.query = $event.target.value
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "labels-list__body" },
          _vm._l(_vm.filteredLabels, function(label) {
            return _c(
              "li",
              { key: label.id, staticClass: "label__list-item" },
              [
                _c(
                  "label",
                  {
                    staticClass: "item",
                    class: {
                      "label-selected": _vm.isLabelSelected(label.title)
                    }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedLabels,
                          expression: "selectedLabels"
                        }
                      ],
                      staticClass: "label-checkbox",
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: label.title,
                        checked: Array.isArray(_vm.selectedLabels)
                          ? _vm._i(_vm.selectedLabels, label.title) > -1
                          : _vm.selectedLabels
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectedLabels,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = label.title,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectedLabels = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedLabels = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedLabels = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "label-title text-truncate" }, [
                      _vm._v(_vm._s(label.title))
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "label-pill",
                      style: { backgroundColor: label.color }
                    })
                  ]
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "footer",
          { staticClass: "labels-list__footer" },
          [
            _c(
              "woot-button",
              {
                attrs: {
                  size: "small",
                  "color-scheme": "primary",
                  disabled: !_vm.selectedLabels.length
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("assign", _vm.selectedLabels)
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("BULK_ACTION.LABELS.ASSIGN_SELECTED_LABELS"))
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }