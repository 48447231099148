var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.onClose,
          expression: "onClose"
        }
      ],
      staticClass: "bulk-action__teams"
    },
    [
      _c("div", { staticClass: "triangle" }, [
        _c(
          "svg",
          { attrs: { height: "12", viewBox: "0 0 24 12", width: "24" } },
          [
            _c("path", {
              attrs: {
                d: "M20 12l-8-8-12 12",
                fill: "var(--white)",
                "fill-rule": "evenodd",
                stroke: "var(--s-50)",
                "stroke-width": "1px"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header flex-between" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("BULK_ACTION.TEAMS.TEAM_SELECT_LABEL")))
          ]),
          _vm._v(" "),
          _c("woot-button", {
            attrs: {
              size: "tiny",
              variant: "clear",
              "color-scheme": "secondary",
              icon: "dismiss"
            },
            on: { click: _vm.onClose }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "team__list-container" }, [
          _c(
            "ul",
            [
              _c("li", { staticClass: "search-container" }, [
                _c(
                  "div",
                  { staticClass: "agent-list-search flex-between" },
                  [
                    _c("fluent-icon", {
                      staticClass: "search-icon",
                      attrs: { icon: "search", size: "16" }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.query,
                          expression: "query"
                        }
                      ],
                      ref: "search",
                      staticClass: "agent--search_input",
                      attrs: { type: "search", placeholder: "Search" },
                      domProps: { value: _vm.query },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.query = $event.target.value
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.filteredTeams.length
                ? _vm._l(_vm.filteredTeams, function(team) {
                    return _c("li", { key: team.id }, [
                      _c(
                        "div",
                        {
                          staticClass: "team__list-item",
                          on: {
                            click: function($event) {
                              return _vm.assignTeam(team)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "reports-option__title" }, [
                            _vm._v(_vm._s(team.name))
                          ])
                        ]
                      )
                    ])
                  })
                : _c("li", [
                    _c("div", { staticClass: "team__list-item" }, [
                      _c("span", { staticClass: "reports-option__title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("BULK_ACTION.TEAMS.NO_TEAMS_AVAILABLE"))
                        )
                      ])
                    ])
                  ])
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }