var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c("woot-button", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip.right",
            value: _vm.$t("CHAT_LIST.SORT_TOOLTIP_LABEL"),
            expression: "$t('CHAT_LIST.SORT_TOOLTIP_LABEL')",
            modifiers: { right: true }
          }
        ],
        staticClass: "selector-button",
        attrs: {
          variant: "smooth",
          size: "tiny",
          "color-scheme": "secondary",
          icon: "sort-icon"
        },
        on: { click: _vm.toggleDropdown }
      }),
      _vm._v(" "),
      _vm.showActionsDropdown
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.closeDropdown,
                  expression: "closeDropdown"
                }
              ],
              staticClass: "dropdown-pane dropdown-pane--open basic-filter"
            },
            [
              _c(
                "div",
                { staticClass: "filter__item" },
                [
                  _c("span", [
                    _vm._v(_vm._s(this.$t("CHAT_LIST.CHAT_SORT.STATUS")))
                  ]),
                  _vm._v(" "),
                  _c("filter-item", {
                    attrs: {
                      type: "status",
                      "selected-value": _vm.chatStatus,
                      items: _vm.chatStatusItems,
                      "path-prefix": "CHAT_LIST.CHAT_STATUS_FILTER_ITEMS"
                    },
                    on: { onChangeFilter: _vm.onChangeFilter }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter__item" },
                [
                  _c("span", [
                    _vm._v(_vm._s(this.$t("CHAT_LIST.CHAT_SORT.ORDER_BY")))
                  ]),
                  _vm._v(" "),
                  _c("filter-item", {
                    attrs: {
                      type: "sort",
                      "selected-value": _vm.chatSortFilter,
                      items: _vm.chatSortItems,
                      "path-prefix": "CHAT_LIST.CHAT_SORT_FILTER_ITEMS"
                    },
                    on: { onChangeFilter: _vm.onChangeFilter }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }