var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAIIntegrationEnabled
    ? _c("div", { staticClass: "position-relative" }, [
        !_vm.message
          ? _c(
              "div",
              [
                _vm.isPrivateNote
                  ? _c("woot-button", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top-end",
                          value: _vm.$t(
                            "INTEGRATION_SETTINGS.OPEN_AI.SUMMARY_TITLE"
                          ),
                          expression:
                            "$t('INTEGRATION_SETTINGS.OPEN_AI.SUMMARY_TITLE')",
                          modifiers: { "top-end": true }
                        }
                      ],
                      attrs: {
                        icon: "book-pulse",
                        "color-scheme": "secondary",
                        variant: "smooth",
                        size: "small",
                        "is-loading": _vm.uiFlags.summarize
                      },
                      on: {
                        click: function($event) {
                          return _vm.processEvent("summarize")
                        }
                      }
                    })
                  : _c("woot-button", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top-end",
                          value: _vm.$t(
                            "INTEGRATION_SETTINGS.OPEN_AI.REPLY_TITLE"
                          ),
                          expression:
                            "$t('INTEGRATION_SETTINGS.OPEN_AI.REPLY_TITLE')",
                          modifiers: { "top-end": true }
                        }
                      ],
                      attrs: {
                        icon: "wand",
                        "color-scheme": "secondary",
                        variant: "smooth",
                        size: "small",
                        "is-loading": _vm.uiFlags.reply_suggestion
                      },
                      on: {
                        click: function($event) {
                          return _vm.processEvent("reply_suggestion")
                        }
                      }
                    })
              ],
              1
            )
          : _c(
              "div",
              [
                _c("woot-button", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top-end",
                      value: _vm.$t("INTEGRATION_SETTINGS.OPEN_AI.TITLE"),
                      expression: "$t('INTEGRATION_SETTINGS.OPEN_AI.TITLE')",
                      modifiers: { "top-end": true }
                    }
                  ],
                  attrs: {
                    icon: "text-grammar-wand",
                    "color-scheme": "secondary",
                    variant: "smooth",
                    size: "small"
                  },
                  on: { click: _vm.toggleDropdown }
                }),
                _vm._v(" "),
                _vm.showDropdown
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.closeDropdown,
                            expression: "closeDropdown"
                          }
                        ],
                        staticClass:
                          "dropdown-pane dropdown-pane--open ai-modal"
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "sub-block-title margin-top-1" },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.$t("INTEGRATION_SETTINGS.OPEN_AI.TITLE")
                                ) +
                                "\n      "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("INTEGRATION_SETTINGS.OPEN_AI.SUBTITLE")
                              ) +
                              "\n      "
                          )
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INTEGRATION_SETTINGS.OPEN_AI.TONE.TITLE"
                                )
                              ) +
                              "\n      "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "tone__item" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.activeTone,
                                  expression: "activeTone"
                                }
                              ],
                              staticClass: "status--filter small",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.activeTone = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.tones, function(tone) {
                              return _c(
                                "option",
                                {
                                  key: tone.key,
                                  domProps: { value: tone.key }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(tone.value) +
                                      "\n          "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "modal-footer flex-container align-right"
                          },
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: { variant: "clear", size: "small" },
                                on: { click: _vm.closeDropdown }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t(
                                        "INTEGRATION_SETTINGS.OPEN_AI.BUTTONS.CANCEL"
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  "is-loading": _vm.uiFlags.rephrase,
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.processEvent("rephrase")
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.buttonText) +
                                    "\n        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }