var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bulk-action__container" }, [
    _c(
      "div",
      { staticClass: "flex-between" },
      [
        _c("label", { staticClass: "bulk-action__panel flex-between" }, [
          _c("input", {
            ref: "selectAllCheck",
            staticClass: "checkbox",
            attrs: { type: "checkbox" },
            domProps: {
              checked: _vm.allConversationsSelected,
              indeterminate: !_vm.allConversationsSelected
            },
            on: {
              change: function($event) {
                return _vm.selectAll($event)
              }
            }
          }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("BULK_ACTION.CONVERSATIONS_SELECTED", {
                    conversationCount: _vm.conversations.length
                  })
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bulk-action__actions flex-between" },
          [
            _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("BULK_ACTION.LABELS.ASSIGN_LABELS"),
                  expression: "$t('BULK_ACTION.LABELS.ASSIGN_LABELS')"
                }
              ],
              staticClass: "margin-right-smaller",
              attrs: {
                size: "tiny",
                variant: "smooth",
                "color-scheme": "secondary",
                icon: "tag"
              },
              on: { click: _vm.toggleLabelActions }
            }),
            _vm._v(" "),
            _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("BULK_ACTION.UPDATE.CHANGE_STATUS"),
                  expression: "$t('BULK_ACTION.UPDATE.CHANGE_STATUS')"
                }
              ],
              staticClass: "margin-right-smaller",
              attrs: {
                size: "tiny",
                variant: "smooth",
                "color-scheme": "secondary",
                icon: "repeat"
              },
              on: { click: _vm.toggleUpdateActions }
            }),
            _vm._v(" "),
            _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("BULK_ACTION.ASSIGN_AGENT_TOOLTIP"),
                  expression: "$t('BULK_ACTION.ASSIGN_AGENT_TOOLTIP')"
                }
              ],
              staticClass: "margin-right-smaller",
              attrs: {
                size: "tiny",
                variant: "smooth",
                "color-scheme": "secondary",
                icon: "person-assign"
              },
              on: { click: _vm.toggleAgentList }
            }),
            _vm._v(" "),
            _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("BULK_ACTION.ASSIGN_TEAM_TOOLTIP"),
                  expression: "$t('BULK_ACTION.ASSIGN_TEAM_TOOLTIP')"
                }
              ],
              attrs: {
                size: "tiny",
                variant: "smooth",
                "color-scheme": "secondary",
                icon: "people-team-add"
              },
              on: { click: _vm.toggleTeamsList }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "popover-animation" } },
          [
            _vm.showLabelActions
              ? _c("label-actions", {
                  staticClass: "label-actions-box",
                  on: {
                    assign: _vm.assignLabels,
                    close: function($event) {
                      _vm.showLabelActions = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "popover-animation" } },
          [
            _vm.showUpdateActions
              ? _c("update-actions", {
                  staticClass: "update-actions-box",
                  attrs: {
                    "selected-inboxes": _vm.selectedInboxes,
                    "conversation-count": _vm.conversations.length,
                    "show-resolve": !_vm.showResolvedAction,
                    "show-reopen": !_vm.showOpenAction,
                    "show-snooze": !_vm.showSnoozedAction
                  },
                  on: {
                    update: _vm.updateConversations,
                    close: function($event) {
                      _vm.showUpdateActions = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "popover-animation" } },
          [
            _vm.showAgentsList
              ? _c("agent-selector", {
                  staticClass: "agent-actions-box",
                  attrs: {
                    "selected-inboxes": _vm.selectedInboxes,
                    "conversation-count": _vm.conversations.length
                  },
                  on: {
                    select: _vm.submit,
                    close: function($event) {
                      _vm.showAgentsList = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "popover-animation" } },
          [
            _vm.showTeamsList
              ? _c("team-actions", {
                  staticClass: "team-actions-box",
                  on: {
                    "assign-team": _vm.assignTeam,
                    close: function($event) {
                      _vm.showTeamsList = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.allConversationsSelected
      ? _c("div", { staticClass: "bulk-action__alert" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("BULK_ACTION.ALL_CONVERSATIONS_SELECTED_ALERT")) +
              "\n  "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }