var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "table-actions-wrap" }, [
      _c(
        "div",
        { staticClass: "left-aligned-wrap" },
        [
          _c("woot-sidemenu-icon"),
          _vm._v(" "),
          _c("h1", { staticClass: "page-title header-title" }, [
            _vm._v("\n        " + _vm._s(_vm.headerTitle) + "\n      ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-aligned-wrap" },
        [
          _c(
            "div",
            { staticClass: "search-wrap" },
            [
              _c("fluent-icon", {
                staticClass: "search-icon",
                attrs: { icon: "search" }
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "contact-search",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER")
                },
                domProps: { value: _vm.searchQuery },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSearchSubmit($event)
                  },
                  input: _vm.onInputSearch
                }
              }),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  staticClass: "clear",
                  attrs: {
                    "is-loading": false,
                    "class-names": _vm.searchButtonClass
                  },
                  on: { click: _vm.onSearchSubmit }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CONTACTS_PAGE.SEARCH_BUTTON")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasActiveSegments
            ? _c(
                "woot-button",
                {
                  staticClass: "margin-right-1 clear",
                  attrs: { "color-scheme": "alert", icon: "delete" },
                  on: { click: _vm.onToggleDeleteSegmentsModal }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CONTACTS_PAGE.FILTER_CONTACTS_DELETE")) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasActiveSegments
            ? _c(
                "div",
                { staticClass: "filters__button-wrap" },
                [
                  _vm.hasAppliedFilters
                    ? _c("div", { staticClass: "filters__applied-indicator" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      staticClass: "margin-right-1 clear",
                      attrs: {
                        "color-scheme": "secondary",
                        "data-testid": "create-new-contact",
                        icon: "filter"
                      },
                      on: { click: _vm.onToggleFilter }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("CONTACTS_PAGE.FILTER_CONTACTS")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAppliedFilters && !_vm.hasActiveSegments
            ? _c(
                "woot-button",
                {
                  staticClass: "margin-right-1 clear",
                  attrs: {
                    "color-scheme": "alert",
                    variant: "clear",
                    icon: "save"
                  },
                  on: { click: _vm.onToggleSegmentsModal }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CONTACTS_PAGE.FILTER_CONTACTS_SAVE")) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "margin-right-1 clear",
              attrs: {
                "color-scheme": "success",
                icon: "person-add",
                "data-testid": "create-new-contact"
              },
              on: { click: _vm.onToggleCreate }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CREATE_CONTACT.BUTTON_LABEL")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _vm.isAdmin
            ? _c(
                "woot-button",
                {
                  staticClass: "clear",
                  attrs: { "color-scheme": "info", icon: "upload" },
                  on: { click: _vm.onToggleImport }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("IMPORT_CONTACTS.BUTTON_LABEL")) +
                      "\n      "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }