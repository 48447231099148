var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "small-8 columns with-right-space " },
          [
            !_vm.uiFlags.fetchingList && !_vm.records.length
              ? _c("p", { staticClass: "no-items-error-message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("AUDIT_LOGS.LIST.404")) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.uiFlags.fetchingList
              ? _c("woot-loading-state", {
                  attrs: { message: _vm.$t("AUDIT_LOGS.LOADING") }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.uiFlags.fetchingList && _vm.records.length
              ? _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(_vm.$t("AUDIT_LOGS.LIST.TABLE_HEADER"), function(
                      thHeader
                    ) {
                      return _c("th", { key: thHeader }, [
                        _vm._v(
                          "\n            " + _vm._s(thHeader) + "\n          "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function(auditLogItem) {
                      return _c("tr", { key: auditLogItem.id }, [
                        _c("td", { staticClass: "wrap-break-words" }, [
                          _vm._v(_vm._s(auditLogItem.username))
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "wrap-break-words" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(auditLogItem.auditable_type) +
                              "." +
                              _vm._s(auditLogItem.action) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "remote-address" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(auditLogItem.remote_address) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "wrap-break-words" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.dynamicTime(auditLogItem.created_at)) +
                              "\n            "
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("table-footer", {
        attrs: {
          "current-page": Number(_vm.meta.currentPage),
          "total-count": _vm.meta.totalEntries,
          "page-size": _vm.meta.perPage
        },
        on: { "page-change": _vm.onPageChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }