var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "phone-input--wrap" }, [
    _c(
      "div",
      { staticClass: "phone-input", class: { "has-error": _vm.error } },
      [
        _c(
          "div",
          {
            staticClass: "country-emoji--wrap",
            on: { click: _vm.toggleCountryDropdown }
          },
          [
            _vm.activeCountry.emoji
              ? _c("h5", [_vm._v(_vm._s(_vm.activeCountry.emoji))])
              : _c("fluent-icon", {
                  staticClass: "fluent-icon",
                  attrs: { icon: "globe", size: "16" }
                }),
            _vm._v(" "),
            _c("fluent-icon", {
              staticClass: "fluent-icon",
              attrs: { icon: "chevron-down", size: "12" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.activeDialCode
          ? _c("span", { staticClass: "country-dial--code" }, [
              _vm._v("\n      " + _vm._s(_vm.activeDialCode) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          staticClass: "phone-input--field",
          style: _vm.styles,
          attrs: {
            type: "tel",
            placeholder: _vm.placeholder,
            readonly: _vm.readonly
          },
          domProps: { value: _vm.phoneNumber },
          on: { input: _vm.onChange, blur: _vm.onBlur }
        })
      ]
    ),
    _vm._v(" "),
    _vm.showDropdown
      ? _c(
          "div",
          { ref: "dropdown", staticClass: "country-dropdown" },
          [
            _c("div", { staticClass: "dropdown-search--wrap" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchCountry,
                    expression: "searchCountry"
                  }
                ],
                ref: "searchbar",
                staticClass: "dropdown-search",
                attrs: { type: "text", placeholder: "Search" },
                domProps: { value: _vm.searchCountry },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchCountry = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm._l(_vm.filteredCountriesBySearch, function(country, index) {
              return _c(
                "div",
                {
                  key: index,
                  ref: "dropdownItem",
                  refInFor: true,
                  staticClass: "country-dropdown--item",
                  class: {
                    active: country.id === _vm.activeCountryCode,
                    focus: index === _vm.selectedIndex
                  },
                  on: {
                    click: function($event) {
                      return _vm.onSelectCountry(country)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "country-emoji" }, [
                    _vm._v(_vm._s(country.emoji))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "country-name" }, [
                    _vm._v("\n        " + _vm._s(country.name) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "country-dial-code" }, [
                    _vm._v(_vm._s(country.dial_code))
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _vm.filteredCountriesBySearch.length === 0
              ? _c("div", [
                  _c("span", { staticClass: "no-results" }, [
                    _vm._v("No results found")
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }