var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings columns container" },
    [
      _c(
        "setting-intro-banner",
        {
          attrs: {
            "header-image": _vm.inbox.avatarUrl,
            "header-title": _vm.inboxName
          }
        },
        [
          _c(
            "woot-tabs",
            {
              staticClass: "settings--tabs",
              attrs: { index: _vm.selectedTabIndex, border: false },
              on: { change: _vm.onTabChange }
            },
            _vm._l(_vm.tabs, function(tab) {
              return _c("woot-tabs-item", {
                key: tab.key,
                attrs: { name: tab.name, "show-badge": false }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedTabKey === "inbox_settings"
        ? _c(
            "div",
            { staticClass: "settings--content" },
            [
              _c(
                "settings-section",
                {
                  attrs: {
                    title: _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE"
                    ),
                    "sub-title": _vm.$t(
                      "INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT"
                    )
                  }
                },
                [
                  _c("woot-avatar-uploader", {
                    staticClass: "settings-item",
                    attrs: {
                      label: _vm.$t(
                        "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL"
                      ),
                      src: _vm.avatarUrl,
                      "delete-avatar": ""
                    },
                    on: {
                      change: _vm.handleImageUpload,
                      onAvatarDelete: _vm.handleAvatarDelete
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    staticClass: "medium-9 columns settings-item",
                    class: { error: _vm.$v.selectedInboxName.$error },
                    attrs: {
                      label: _vm.inboxNameLabel,
                      placeholder: _vm.inboxNamePlaceHolder,
                      error: _vm.$v.selectedInboxName.$error
                        ? _vm.$t("INBOX_MGMT.ADD.CHANNEL_NAME.ERROR")
                        : ""
                    },
                    on: { blur: _vm.$v.selectedInboxName.$touch },
                    model: {
                      value: _vm.selectedInboxName,
                      callback: function($$v) {
                        _vm.selectedInboxName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "selectedInboxName"
                    }
                  }),
                  _vm._v(" "),
                  _vm.isATwitterInbox
                    ? _c(
                        "label",
                        {
                          staticClass: "toggle-input-wrap",
                          attrs: { for: "toggle-business-hours" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tweetsEnabled,
                                expression: "tweetsEnabled"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              name: "toggle-business-hours"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.tweetsEnabled)
                                ? _vm._i(_vm.tweetsEnabled, null) > -1
                                : _vm.tweetsEnabled
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.tweetsEnabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.tweetsEnabled = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.tweetsEnabled = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.tweetsEnabled = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("INBOX_MGMT.ADD.TWITTER.TWEETS.ENABLE")
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAPIInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        class: { error: _vm.$v.webhookUrl.$error },
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER"
                          ),
                          error: _vm.$v.webhookUrl.$error
                            ? _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR"
                              )
                            : ""
                        },
                        on: { blur: _vm.$v.webhookUrl.$touch },
                        model: {
                          value: _vm.webhookUrl,
                          callback: function($$v) {
                            _vm.webhookUrl =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "webhookUrl"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER"
                          )
                        },
                        model: {
                          value: _vm.channelWebsiteUrl,
                          callback: function($$v) {
                            _vm.channelWebsiteUrl =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "channelWebsiteUrl"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER"
                          )
                        },
                        model: {
                          value: _vm.channelWelcomeTitle,
                          callback: function($$v) {
                            _vm.channelWelcomeTitle =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "channelWelcomeTitle"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("woot-input", {
                        staticClass: "medium-9 columns settings-item",
                        attrs: {
                          label: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL"
                          ),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER"
                          )
                        },
                        model: {
                          value: _vm.channelWelcomeTagline,
                          callback: function($$v) {
                            _vm.channelWelcomeTagline =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "channelWelcomeTagline"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL"
                                )
                              ) +
                              "\n        "
                          ),
                          _c("woot-color-picker", {
                            model: {
                              value: _vm.inbox.widget_color,
                              callback: function($$v) {
                                _vm.$set(_vm.inbox, "widget_color", $$v)
                              },
                              expression: "inbox.widget_color"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWhatsAppChannel
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL"
                                )
                              ) +
                              "\n        "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.whatsAppAPIProviderName,
                                expression: "whatsAppAPIProviderName"
                              }
                            ],
                            attrs: { type: "text", disabled: "" },
                            domProps: { value: _vm.whatsAppAPIProviderName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.whatsAppAPIProviderName =
                                  $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "medium-9 columns settings-item" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL"
                            )
                          ) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.greetingEnabled,
                              expression: "greetingEnabled"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.greetingEnabled = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED"
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "help-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.greetingEnabled
                    ? _c(
                        "div",
                        { staticClass: "settings-item" },
                        [
                          _c("greetings-editor", {
                            attrs: {
                              label: _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL"
                              ),
                              placeholder: _vm.$t(
                                "INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER"
                              ),
                              richtext: !_vm.textAreaChannels
                            },
                            model: {
                              value: _vm.greetingMessage,
                              callback: function($$v) {
                                _vm.greetingMessage =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "greetingMessage"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.replyTime,
                                  expression: "replyTime"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.replyTime = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  key: "in_a_few_minutes",
                                  attrs: { value: "in_a_few_minutes" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  key: "in_a_few_hours",
                                  attrs: { value: "in_a_few_hours" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                {
                                  key: "in_a_day",
                                  attrs: { value: "in_a_day" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.emailCollectEnabled,
                                  expression: "emailCollectEnabled"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.emailCollectEnabled = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "medium-9 columns settings-item" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT")
                          ) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.csatSurveyEnabled,
                              expression: "csatSurveyEnabled"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.csatSurveyEnabled = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: true } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED")
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: false } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED")
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "help-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT_SUB_TEXT"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.allowMessagesAfterResolved,
                                  expression: "allowMessagesAfterResolved"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.allowMessagesAfterResolved = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.ENABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.ALLOW_MESSAGES_AFTER_RESOLVED.DISABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.ALLOW_MESSAGES_AFTER_RESOLVED_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.continuityViaEmail,
                                  expression: "continuityViaEmail"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.continuityViaEmail = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.ENABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.ENABLE_CONTINUITY_VIA_EMAIL.DISABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.ENABLE_CONTINUITY_VIA_EMAIL_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "medium-9 settings-item settings-item" },
                    [
                      _c("label", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("INBOX_MGMT.HELP_CENTER.LABEL")) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedPortalSlug,
                              expression: "selectedPortalSlug"
                            }
                          ],
                          staticClass: "filter__question",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedPortalSlug = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("INBOX_MGMT.HELP_CENTER.PLACEHOLDER")
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.portals, function(p) {
                            return _c(
                              "option",
                              { key: p.slug, domProps: { value: p.slug } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(p.name) +
                                    "\n          "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "help-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("INBOX_MGMT.HELP_CENTER.SUB_TEXT")) +
                            "\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.canLocktoSingleConversation
                    ? _c(
                        "label",
                        { staticClass: "medium-9 columns settings-item" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.SETTINGS_POPUP.LOCK_TO_SINGLE_CONVERSATION"
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.locktoSingleConversation,
                                  expression: "locktoSingleConversation"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.locktoSingleConversation = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: true } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.LOCK_TO_SINGLE_CONVERSATION.ENABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("option", { domProps: { value: false } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "INBOX_MGMT.EDIT.LOCK_TO_SINGLE_CONVERSATION.DISABLED"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "help-text" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.SETTINGS_POPUP.LOCK_TO_SINGLE_CONVERSATION_SUB_TEXT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c("label", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("INBOX_MGMT.FEATURES.LABEL")) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "div",
                        { staticClass: "widget--feature-flag settings-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFeatureFlags,
                                expression: "selectedFeatureFlags"
                              }
                            ],
                            attrs: { type: "checkbox", value: "attachments" },
                            domProps: {
                              checked: Array.isArray(_vm.selectedFeatureFlags)
                                ? _vm._i(
                                    _vm.selectedFeatureFlags,
                                    "attachments"
                                  ) > -1
                                : _vm.selectedFeatureFlags
                            },
                            on: {
                              input: _vm.handleFeatureFlag,
                              change: function($event) {
                                var $$a = _vm.selectedFeatureFlags,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "attachments",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedFeatureFlags = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedFeatureFlags = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedFeatureFlags = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "attachments" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "div",
                        { staticClass: "settings-item settings-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFeatureFlags,
                                expression: "selectedFeatureFlags"
                              }
                            ],
                            attrs: { type: "checkbox", value: "emoji_picker" },
                            domProps: {
                              checked: Array.isArray(_vm.selectedFeatureFlags)
                                ? _vm._i(
                                    _vm.selectedFeatureFlags,
                                    "emoji_picker"
                                  ) > -1
                                : _vm.selectedFeatureFlags
                            },
                            on: {
                              input: _vm.handleFeatureFlag,
                              change: function($event) {
                                var $$a = _vm.selectedFeatureFlags,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "emoji_picker",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedFeatureFlags = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedFeatureFlags = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedFeatureFlags = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "emoji_picker" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "div",
                        { staticClass: "settings-item settings-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFeatureFlags,
                                expression: "selectedFeatureFlags"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              value: "end_conversation"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.selectedFeatureFlags)
                                ? _vm._i(
                                    _vm.selectedFeatureFlags,
                                    "end_conversation"
                                  ) > -1
                                : _vm.selectedFeatureFlags
                            },
                            on: {
                              input: _vm.handleFeatureFlag,
                              change: function($event) {
                                var $$a = _vm.selectedFeatureFlags,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "end_conversation",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedFeatureFlags = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedFeatureFlags = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedFeatureFlags = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "end_conversation" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.FEATURES.ALLOW_END_CONVERSATION"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAWebWidgetInbox
                    ? _c(
                        "div",
                        { staticClass: "settings-item settings-item" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedFeatureFlags,
                                expression: "selectedFeatureFlags"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              value: "use_inbox_avatar_for_bot"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.selectedFeatureFlags)
                                ? _vm._i(
                                    _vm.selectedFeatureFlags,
                                    "use_inbox_avatar_for_bot"
                                  ) > -1
                                : _vm.selectedFeatureFlags
                            },
                            on: {
                              input: _vm.handleFeatureFlag,
                              change: function($event) {
                                var $$a = _vm.selectedFeatureFlags,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "use_inbox_avatar_for_bot",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedFeatureFlags = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedFeatureFlags = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedFeatureFlags = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "emoji_picker" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.FEATURES.USE_INBOX_AVATAR_FOR_BOT"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAPIInbox
                    ? _c("woot-submit-button", {
                        attrs: {
                          type: "submit",
                          disabled: _vm.$v.webhookUrl.$invalid,
                          "button-text": _vm.$t(
                            "INBOX_MGMT.SETTINGS_POPUP.UPDATE"
                          ),
                          loading: _vm.uiFlags.isUpdating
                        },
                        on: { click: _vm.updateInbox }
                      })
                    : _c("woot-submit-button", {
                        attrs: {
                          type: "submit",
                          disabled: _vm.$v.$invalid,
                          "button-text": _vm.$t(
                            "INBOX_MGMT.SETTINGS_POPUP.UPDATE"
                          ),
                          loading: _vm.uiFlags.isUpdating
                        },
                        on: { click: _vm.updateInbox }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _vm.isAFacebookInbox
                ? _c("facebook-reauthorize", {
                    attrs: { "inbox-id": _vm.inbox.id }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "collaborators"
        ? _c(
            "div",
            { staticClass: "settings--content" },
            [_c("collaborators-page", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "configuration"
        ? _c(
            "div",
            [_c("configuration-page", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "preChatForm"
        ? _c(
            "div",
            [_c("pre-chat-form-settings", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "businesshours"
        ? _c(
            "div",
            [_c("weekly-availability", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "widgetBuilder"
        ? _c("div", [_c("widget-builder", { attrs: { inbox: _vm.inbox } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTabKey === "botConfiguration"
        ? _c(
            "div",
            [_c("bot-configuration", { attrs: { inbox: _vm.inbox } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }