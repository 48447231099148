var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-box", class: _vm.wrapClass }, [
    _c(
      "div",
      { staticClass: "left-wrap" },
      [
        _c("woot-button", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top-end",
              value: _vm.$t("CONVERSATION.REPLYBOX.TIP_EMOJI_ICON"),
              expression: "$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')",
              modifiers: { "top-end": true }
            }
          ],
          attrs: {
            title: _vm.$t("CONVERSATION.REPLYBOX.TIP_EMOJI_ICON"),
            icon: "emoji",
            emoji: "😊",
            "color-scheme": "secondary",
            variant: "smooth",
            size: "small"
          },
          on: { click: _vm.toggleEmojiPicker }
        }),
        _vm._v(" "),
        _c(
          "file-upload",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top-end",
                value: _vm.$t("CONVERSATION.REPLYBOX.TIP_ATTACH_ICON"),
                expression: "$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')",
                modifiers: { "top-end": true }
              }
            ],
            ref: "upload",
            attrs: {
              size: 4096 * 4096,
              accept: _vm.allowedFileTypes,
              multiple: _vm.enableMultipleFileUpload,
              drop: true,
              "drop-directory": false,
              data: {
                direct_upload_url: "/rails/active_storage/direct_uploads",
                direct_upload: true
              }
            },
            on: { "input-file": _vm.onFileUpload }
          },
          [
            _vm.showAttachButton
              ? _c("woot-button", {
                  attrs: {
                    "class-names": "button--upload",
                    title: _vm.$t("CONVERSATION.REPLYBOX.TIP_ATTACH_ICON"),
                    icon: "attach",
                    emoji: "📎",
                    "color-scheme": "secondary",
                    variant: "smooth",
                    size: "small"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.showAudioRecorderButton
          ? _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top-end",
                  value: _vm.$t("CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON"),
                  expression:
                    "$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')",
                  modifiers: { "top-end": true }
                }
              ],
              attrs: {
                icon: !_vm.isRecordingAudio ? "microphone" : "microphone-off",
                emoji: "🎤",
                "color-scheme": !_vm.isRecordingAudio ? "secondary" : "alert",
                variant: "smooth",
                size: "small"
              },
              on: { click: _vm.toggleAudioRecorder }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showEditorToggle
          ? _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top-end",
                  value: _vm.$t("CONVERSATION.REPLYBOX.TIP_FORMAT_ICON"),
                  expression: "$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')",
                  modifiers: { "top-end": true }
                }
              ],
              attrs: {
                icon: "quote",
                emoji: "🖊️",
                "color-scheme": "secondary",
                variant: "smooth",
                size: "small"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("toggle-editor")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showAudioPlayStopButton
          ? _c(
              "woot-button",
              {
                attrs: {
                  icon: _vm.audioRecorderPlayStopIcon,
                  emoji: "🎤",
                  "color-scheme": "secondary",
                  variant: "smooth",
                  size: "small"
                },
                on: { click: _vm.toggleAudioRecorderPlayPause }
              },
              [_c("span", [_vm._v(_vm._s(_vm.recordingAudioDurationText))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showMessageSignatureButton
          ? _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top-end",
                  value: _vm.signatureToggleTooltip,
                  expression: "signatureToggleTooltip",
                  modifiers: { "top-end": true }
                }
              ],
              attrs: {
                icon: "signature",
                "color-scheme": "secondary",
                variant: "smooth",
                size: "small",
                title: _vm.signatureToggleTooltip
              },
              on: { click: _vm.toggleMessageSignature }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasWhatsappTemplates
          ? _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top-end",
                  value: "Whatsapp Templates",
                  expression: "'Whatsapp Templates'",
                  modifiers: { "top-end": true }
                }
              ],
              attrs: {
                icon: "whatsapp",
                "color-scheme": "secondary",
                variant: "smooth",
                size: "small",
                title: "Whatsapp Templates"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("selectWhatsappTemplate")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        (_vm.isAWebWidgetInbox || _vm.isAPIInbox) && !_vm.isOnPrivateNote
          ? _c("video-call-button", {
              attrs: { "conversation-id": _vm.conversationId }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("AIAssistanceButton", {
          attrs: {
            "conversation-id": _vm.conversationId,
            "is-private-note": _vm.isOnPrivateNote,
            message: _vm.message
          },
          on: { "replace-text": _vm.replaceText }
        }),
        _vm._v(" "),
        _c("transition", { attrs: { name: "modal-fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$refs.upload && _vm.$refs.upload.dropActive,
                  expression: "$refs.upload && $refs.upload.dropActive"
                }
              ],
              staticClass: "modal-mask"
            },
            [
              _c("fluent-icon", { attrs: { icon: "cloud-backup" } }),
              _vm._v(" "),
              _c("h4", { staticClass: "page-sub-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("CONVERSATION.REPLYBOX.DRAG_DROP")) +
                    "\n        "
                )
              ])
            ],
            1
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-wrap" },
      [
        _c(
          "woot-button",
          {
            attrs: {
              size: "small",
              "class-names": _vm.buttonClass,
              "is-disabled": _vm.isSendDisabled
            },
            on: { click: _vm.onSend }
          },
          [_vm._v("\n      " + _vm._s(_vm.sendButtonText) + "\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }