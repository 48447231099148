var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings--content" },
    [
      _c(
        "settings-section",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.BUSINESS_HOURS.TITLE"),
            "sub-title": _vm.$t("INBOX_MGMT.BUSINESS_HOURS.SUBTITLE")
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateInbox($event)
                }
              }
            },
            [
              _c(
                "label",
                {
                  staticClass: "toggle-input-wrap",
                  attrs: { for: "toggle-business-hours" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isBusinessHoursEnabled,
                        expression: "isBusinessHoursEnabled"
                      }
                    ],
                    attrs: { type: "checkbox", name: "toggle-business-hours" },
                    domProps: {
                      checked: Array.isArray(_vm.isBusinessHoursEnabled)
                        ? _vm._i(_vm.isBusinessHoursEnabled, null) > -1
                        : _vm.isBusinessHoursEnabled
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.isBusinessHoursEnabled,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.isBusinessHoursEnabled = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isBusinessHoursEnabled = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isBusinessHoursEnabled = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("INBOX_MGMT.BUSINESS_HOURS.TOGGLE_AVAILABILITY")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("INBOX_MGMT.BUSINESS_HOURS.TOGGLE_HELP")))
              ]),
              _vm._v(" "),
              _vm.isBusinessHoursEnabled
                ? _c(
                    "div",
                    { staticClass: "business-hours-wrap" },
                    [
                      _c("label", { staticClass: "unavailable-input-wrap" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.BUSINESS_HOURS.UNAVAILABLE_MESSAGE_LABEL"
                              )
                            ) +
                            "\n          "
                        ),
                        _vm.isRichEditorEnabled
                          ? _c(
                              "label",
                              { staticClass: "richtext" },
                              [
                                _c("woot-message-editor", {
                                  staticClass: "input",
                                  attrs: {
                                    "enable-variables": true,
                                    "is-format-mode": true,
                                    "min-height": 4
                                  },
                                  model: {
                                    value: _vm.unavailableMessage,
                                    callback: function($$v) {
                                      _vm.unavailableMessage = $$v
                                    },
                                    expression: "unavailableMessage"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.unavailableMessage,
                                  expression: "unavailableMessage"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.unavailableMessage },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.unavailableMessage = $event.target.value
                                }
                              }
                            })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "timezone-input-wrap" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "INBOX_MGMT.BUSINESS_HOURS.TIMEZONE_LABEL"
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("multiselect", {
                            attrs: {
                              options: _vm.timeZones,
                              "deselect-label": "",
                              "select-label": "",
                              "selected-label": "",
                              "track-by": "value",
                              label: "label",
                              "close-on-select": true,
                              placeholder: _vm.$t(
                                "INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE"
                              ),
                              "allow-empty": false
                            },
                            model: {
                              value: _vm.timeZone,
                              callback: function($$v) {
                                _vm.timeZone = $$v
                              },
                              expression: "timeZone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("INBOX_MGMT.BUSINESS_HOURS.WEEKLY_TITLE")
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.timeSlots, function(timeSlot) {
                        return _c("business-day", {
                          key: timeSlot.day,
                          attrs: {
                            "day-name": _vm.dayNames[timeSlot.day],
                            "time-slot": timeSlot
                          },
                          on: {
                            update: function(data) {
                              return _vm.onSlotUpdate(timeSlot.day, data)
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("woot-submit-button", {
                attrs: {
                  "button-text": _vm.$t("INBOX_MGMT.BUSINESS_HOURS.UPDATE"),
                  loading: _vm.uiFlags.isUpdating,
                  disabled: _vm.hasError
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }