var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option-item--inbox" }, [
    _c(
      "span",
      { staticClass: "badge--icon" },
      [
        _c("fluent-icon", {
          attrs: { icon: _vm.computedInboxIcon, size: "14" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "option__user-data" }, [
      _c("h5", { staticClass: "option__title" }, [
        _vm._v("\n      " + _vm._s(_vm.name) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "option__body text-truncate",
          attrs: { title: _vm.inboxIdentifier }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.inboxIdentifier || _vm.computedInboxType) +
              "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }