var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "search-result-section",
    {
      attrs: {
        title: _vm.$t("SEARCH.SECTION.MESSAGES"),
        empty: !_vm.messages.length,
        query: _vm.query,
        "show-title": _vm.showTitle,
        "is-fetching": _vm.isFetching
      }
    },
    [
      _c(
        "ul",
        { staticClass: "search-list" },
        _vm._l(_vm.messages, function(message) {
          return _c(
            "li",
            { key: message.id },
            [
              _c(
                "search-result-conversation-item",
                {
                  attrs: {
                    id: message.conversation_id,
                    "account-id": _vm.accountId,
                    inbox: message.inbox,
                    "created-at": message.created_at,
                    "message-id": message.id
                  }
                },
                [
                  _c("message-content", {
                    attrs: {
                      author: _vm.getName(message),
                      content: message.content,
                      "search-term": _vm.query
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }