var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c(
          "woot-button",
          {
            staticClass: "back-button",
            attrs: { icon: "chevron-left", variant: "smooth", size: "small " },
            on: { click: _vm.onBack }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("GENERAL_SETTINGS.BACK")) + "\n    "
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("section", { staticClass: "search-root" }, [
      _c(
        "header",
        [
          _c("search-header", { on: { search: _vm.onSearch } }),
          _vm._v(" "),
          _vm.query
            ? _c("search-tabs", {
                attrs: { tabs: _vm.tabs, "selected-tab": _vm.activeTabIndex },
                on: {
                  "tab-change": function(tab) {
                    return (_vm.selectedTab = tab)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "search-results" }, [
        _vm.showResultsSection
          ? _c(
              "div",
              [
                _vm.filterContacts
                  ? _c("search-result-contacts-list", {
                      attrs: {
                        "is-fetching": _vm.uiFlags.contact.isFetching,
                        contacts: _vm.contacts,
                        query: _vm.query,
                        "show-title": _vm.isSelectedTabAll
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.filterMessages
                  ? _c("search-result-messages-list", {
                      attrs: {
                        "is-fetching": _vm.uiFlags.message.isFetching,
                        messages: _vm.messages,
                        query: _vm.query,
                        "show-title": _vm.isSelectedTabAll
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.filterConversations
                  ? _c("search-result-conversations-list", {
                      attrs: {
                        "is-fetching": _vm.uiFlags.conversation.isFetching,
                        conversations: _vm.conversations,
                        query: _vm.query,
                        "show-title": _vm.isSelectedTabAll
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm.showEmptySearchResults
          ? _c(
              "div",
              { staticClass: "empty" },
              [
                _c("fluent-icon", {
                  staticClass: "icon",
                  attrs: { icon: "info", size: "16px" }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "empty-state__text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("SEARCH.EMPTY_STATE_FULL", { query: _vm.query })
                      ) +
                      "\n        "
                  )
                ])
              ],
              1
            )
          : _c("div", { staticClass: "empty text-center" }, [
              _c(
                "p",
                { staticClass: "text-center margin-bottom-0" },
                [
                  _c("fluent-icon", {
                    staticClass: "icon",
                    attrs: { icon: "search", size: "24px" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "empty-state__text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("SEARCH.EMPTY_STATE_DEFAULT")) +
                    "\n        "
                )
              ])
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }