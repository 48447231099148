var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "search-result-section",
    {
      attrs: {
        title: _vm.$t("SEARCH.SECTION.CONTACTS"),
        empty: !_vm.contacts.length,
        query: _vm.query,
        "show-title": _vm.showTitle,
        "is-fetching": _vm.isFetching
      }
    },
    [
      _c(
        "ul",
        { staticClass: "search-list" },
        _vm._l(_vm.contacts, function(contact) {
          return _c(
            "li",
            { key: contact.id },
            [
              _c("search-result-contact-item", {
                attrs: {
                  id: contact.id,
                  name: contact.name,
                  email: contact.email,
                  phone: contact.phone_number,
                  "account-id": _vm.accountId,
                  thumbnail: contact.thumbnail
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }