var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-container" },
    [
      !_vm.hasUnreadMessages
        ? _c("menu-item", {
            attrs: { option: _vm.unreadOption, variant: "icon" },
            on: {
              click: function($event) {
                return _vm.$emit("mark-as-unread")
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.statusMenuConfig, function(option) {
        return [
          _vm.show(option.key)
            ? _c("menu-item", {
                key: option.key,
                attrs: { option: option, variant: "icon" },
                on: {
                  click: function($event) {
                    return _vm.toggleStatus(option.key, null)
                  }
                }
              })
            : _vm._e()
        ]
      }),
      _vm._v(" "),
      _c(
        "menu-item-with-submenu",
        { attrs: { option: _vm.snoozeMenuConfig } },
        _vm._l(_vm.snoozeMenuConfig.options, function(option, i) {
          return _c("menu-item", {
            key: i,
            attrs: { option: option },
            on: {
              click: function($event) {
                return _vm.snoozeConversation(option.snoozedUntil)
              }
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "menu-item-with-submenu",
        { attrs: { option: _vm.priorityConfig } },
        _vm._l(_vm.priorityConfig.options, function(option, i) {
          return _c("menu-item", {
            key: i,
            attrs: { option: option },
            on: {
              click: function($event) {
                return _vm.assignPriority(option.key)
              }
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "menu-item-with-submenu",
        {
          attrs: {
            option: _vm.labelMenuConfig,
            "sub-menu-available": !!_vm.labels.length
          }
        },
        [
          _vm._l(_vm.labels, function(label) {
            return _c("menu-item", {
              key: label.id,
              attrs: {
                option: _vm.generateMenuLabelConfig(label, "label"),
                variant: "label"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("assign-label", label)
                }
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "menu-item-with-submenu",
        {
          attrs: {
            option: _vm.agentMenuConfig,
            "sub-menu-available": !!_vm.assignableAgents.length
          }
        },
        [
          _vm.assignableAgentsUiFlags.isFetching
            ? _c("agent-loading-placeholder")
            : _vm._l(_vm.assignableAgents, function(agent) {
                return _c("menu-item", {
                  key: agent.id,
                  attrs: {
                    option: _vm.generateMenuLabelConfig(agent, "agent"),
                    variant: "agent"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("assign-agent", agent)
                    }
                  }
                })
              })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "menu-item-with-submenu",
        {
          attrs: {
            option: _vm.teamMenuConfig,
            "sub-menu-available": !!_vm.teams.length
          }
        },
        _vm._l(_vm.teams, function(team) {
          return _c("menu-item", {
            key: team.id,
            attrs: { option: _vm.generateMenuLabelConfig(team, "team") },
            on: {
              click: function($event) {
                return _vm.$emit("assign-team", team)
              }
            }
          })
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }