var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-icon", {
    attrs: {
      size: _vm.size,
      icon: _vm.icon,
      type: _vm.type,
      icons: _vm.icons,
      "view-box": _vm.viewBox
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }