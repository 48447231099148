var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code--container" },
    [
      _c("div", { staticClass: "code--action-area" }, [
        _vm.enableCodePen
          ? _c(
              "form",
              {
                staticClass: "code--codeopen-form",
                attrs: {
                  action: "https://codepen.io/pen/define",
                  method: "POST",
                  target: "_blank"
                }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "data" },
                  domProps: { value: _vm.codepenScriptValue }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button secondary tiny",
                    attrs: { type: "submit" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("COMPONENTS.CODE.CODEPEN")) +
                        "\n      "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button secondary tiny", on: { click: _vm.onCopy } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("COMPONENTS.CODE.BUTTON_TEXT")) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.script
        ? _c("highlightjs", { attrs: { language: _vm.lang, code: _vm.script } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }