var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.priority
    ? _c(
        "span",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                content: _vm.tooltipText,
                delay: { show: 1500, hide: 0 },
                hideOnClick: true
              },
              expression:
                "{\n    content: tooltipText,\n    delay: { show: 1500, hide: 0 },\n    hideOnClick: true,\n  }"
            }
          ],
          staticClass: "conversation-priority-mark",
          class: { urgent: _vm.priority === _vm.CONVERSATION_PRIORITY.URGENT }
        },
        [
          _c("fluent-icon", {
            attrs: {
              icon: "priority-" + _vm.priority.toLowerCase(),
              size: "14",
              "view-box": "0 0 14 14"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }