var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasOpenedAtleastOnce
    ? _c(
        "div",
        { staticClass: "dashboard-app--container" },
        _vm._l(_vm.config, function(configItem, index) {
          return _c(
            "div",
            { key: index, staticClass: "dashboard-app--list" },
            [
              _vm.iframeLoading
                ? _c("loading-state", {
                    staticClass: "dashboard-app_loading-container",
                    attrs: { message: _vm.$t("DASHBOARD_APPS.LOADING_MESSAGE") }
                  })
                : _vm._e(),
              _vm._v(" "),
              configItem.type === "frame" && configItem.url
                ? _c("iframe", {
                    attrs: {
                      id: "dashboard-app--frame-" + index,
                      src: configItem.url
                    },
                    on: {
                      load: function() {
                        return _vm.onIframeLoad(index)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }